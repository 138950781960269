export const understandYourActions = {
  order: 2,
  name: "Understand your actions in Tower Hunt",
  subcategory: "Intro to Tower Hunt",
  markdown: `# Understand your actions in Tower Hunt
  In Tower Hunt, one person's view of the database may be very different from another's. Commercial real estate operates with asymmetric information, and Tower Hunt was built to embrace this reality. There are dozens of different types of actions you can take in Tower Hunt. Keep reading to learn more about how these features work.

  ## Exploring the map

  | **Action** | **What happens** |
  |---|---|
  | Pan/zoom (local level) | An {% inlineRouterLink articleId="explored-areas" %}explored area{% /inlineRouterLink %} is recorded at the map's center. Only you can see this. |
  | Pan/zoom (city level) | Markers that you have access to are displayed near your {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %}. |
  | Pan/zoom (regional level) | Anonymized active users are displayed within the map boundaries. |
  | Click "+ Property" button | If you're currently at a search bar location, a red marker appears at the location. Otherwise, a red marker appears at your mouse cursor location; click on the map to place it. In either case, Tower Hunt attempts to suggest a name for a new property that you can create by pressing "Save". |

  ## Accessing datapoints

  | **Action** | **What happens** |
  |---|---|
  | View a datapoint you viewed less than 5 minutes ago | Send the datapoint again at no cost. |
  | View a public datapoint **you authored** | If the datapoint was **approved** by validators, send it at no cost. Otherwise, send it after charging the cost. |
  | View someone else's **free** public datapoint | Send it at no cost but award usage credits to the author and validator(s). |
  | View a licensable or safezone datapoint | Send it after charging the cost. If it is public, log the pro rata revenue shares to the author and validator(s). |
  | View a task | Send it after charging the cost. |

  {% callout type="tip" %}
  **Tip**: We have a whole help article about our {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="How much does it cost" %}pay-as-you-go approach{% /inlineRouterLink %}. It's worth a look.
  {% /callout %}

  ## Creating datapoints

  | **Action** | **What happens** |
  |---|---|
  | {% inlineRouterLink articleId="create-a-datapoint" %}Create a public datapoint{% /inlineRouterLink %} | The datapoint is immediately available to you but otherwise not publicly available. The datapoint is submitted for validation. Users with a matching geographic focus are notified that a new validation opportunity is available. |
  | {% inlineRouterLink articleId="create-a-datapoint" %}Create a Safezone datapoint{% /inlineRouterLink %} | The datapoint is immediately available to you and any members of teams which have automatic safezone sharing enabled. No one else can access it. |

  ## Editing and removing datapoints

  | **Action** | **What happens** |
  |---|---|
  | Edit a public datapoint | The edit is immediately available to you but otherwise not publicly available until it is {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}published for validation{% /inlineRouterLink %}. Once it is published, users with a matching geographic focus are notified that a new validation opportunity is available. |
  | Remove a public datapoint | The removal is immediately hidden for you but otherwise still publicly available. The datapoint is submitted for validation. Users with a matching geographic focus are notified that a new validation opportunity is available. |
  | Edit a safezone datapoint | The datapoint is immediately available to you and any members of teams which have automatic safezone sharing enabled. No one else can access it. |
  | Remove a safezone datapoint | The removal is immediately hidden for you and any members of teams which have automatic safezone sharing enabled. |

  ## Validating datapoints

  | **Action** | **What happens** |
  |---|---|
  | Approve or reject a change | Your vote is recorded and your skips increase. When a consensus is reached, you are rewarded or penalized based on the outcome. |
  | Skip a validation | Your skips decrease. |

  ## Unlocking datapoints

  | **Action** | **What happens** |
  |---|---|
  | Unlock a single datapoint | The datapoint becomes accessible on a pay-as-you-go basis. |
  | Unlock a collection of datapoints | All included datapoints become accessible on a pay-as-you-go basis. |
  | Someone accesses a datapoint you contributed | You are awarded your pro rata share of the revenue, if any. Free, public datapoints award usage credits. |

  ## Sharing datapoints

  Only safezone datapoints are shareable.

  | **Action** | **What happens** |
  |---|---|
  | Share a datapoint | The recipient gains access to the datapoint. Payment responsibility is governed by the subsidy dropdown for that recipient. |
  | Select a subsidy time period for a recipient | Controls who will pay when the datapoint is accessed, and for how long. |
  | Revoke access for a recipient | The recipient loses access to the datapoint. |

  ## Declassifying datapoints

  When you convert a private datapoint to a public datapoint, we call this "declassifying".

  | **Action** | **What happens** |
  |---|---|
  | Declassify a datapoint | The datapoint is submitted for validation. It is not publicly available. Users with a matching geographic focus are notified that a new validation opportunity is available. Users who had access to the datapoint when it was in your Safezone still have access. |

  {% callout type="caution" %}
  Certain types of datapoints (ex. contact information) may not be declassified. For a complete list, see {% inlineRouterLink %}Safezone-only datapoints{% /inlineRouterLink %}.
  {% /callout %}

  ## Creating tasks and task lists

  | **Action** | **What happens** |
  |---|---|
  | Create a standalone task | If the task belongs to a shared task list, its members can see it. Otherwise, only you can see it. |
  | Create a task linked to a datapoint | Same as above. Additionally, see {% inlineRouterLink articleId="understand-your-actions-in-tower-hunt" sectionName="Accessing datapoints" %}actions for accessing datapoints{% /inlineRouterLink %}. |
  | Add a due date/time | Upon the due date/time, everyone who has access to the task will be notified via email and in-app notification. |
  | Create a task list | Only you can see the list. |
  | Share a task list | Recipients can see the list and its tasks. |
  | Move a task to a different list | People who can access the destination list can now see the task. |

  ## Adding notes and files

  | **Action** | **What happens** |
  |---|---|
  | Add a note to a datapoint | The note is a safezone datapoint, so it is immediately available to you and any members of teams which have automatic safezone sharing enabled. No one else can access it. |
  | Add a file to a datapoint via upload or third-party account | The file is a safezone datapoint, so it is immediately available to you and any members of teams which have automatic safezone sharing enabled. No one else can access it. |
  | Connect a folder from a third-party account | The folder is a safezone datapoint, so it is immediately available to you and any members of teams which have automatic safezone sharing enabled. No one else can access it. The contents of the folder are not controlled by Tower Hunt, meaning they may change at any time or be accessible to people beyond what is shown in your Safezone. |

  ## Reacting to news and notifications

  News and notifications are delivered as a stack of cards in your Me tab. You can swipe or click on these cards to act upon them.

  | **Action** | **What happens** |
  |---|---|
  | {% inlineAppIcon iconName="star" %}**Save**{% /inlineAppIcon %} a card | A task is created in your "News" list. The card is attached to the task. |
  | {% inlineAppIcon iconName="xMark" %}**Discard**{% /inlineAppIcon %} a card | The card is removed from your stack. |
  | {% inlineAppIcon iconName="unlock" %}**Unlock**{% /inlineAppIcon %} a card | The datapoints you select are unlocked and the card flips over to display its data. Additionally, see {% inlineRouterLink articleId="understand-your-actions-in-tower-hunt" sectionName="Unlocking datapoints" %}actions for unlocking datapoints{% /inlineRouterLink %}. |

  {% callout type="tip" %}
  **New to Tower Hunt?** We offer a series of lessons to help people get in, get comfortable, and get to work in Tower Hunt. Head to {% inlineRouterLink %}Tower Hunt 101{% /inlineRouterLink %} to get started.
  {% /callout %}`,
};
